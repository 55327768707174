import Box from '@mui/material/Box';
import Logo from '../../icons/logo.svg';
import Header from '../Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { getMsalInstance } from '../../utils/get-msal-instance';
import RtpFrame from '../RtpFrame/RtpFrame';

interface Props {
  children: JSX.Element;
}

const appLayoutStyle = {
  backgroundColor: '#f3f3f3',
  marginLeft: '96px'
};

const sidebarStyle = {
  position: 'fixed',
  left: 0,
  top: 0,
  background: '#000',
  width: '96px',
  height: '100%',
  padding: '24px 18px'
};

const containerStyle = {
  padding: '24px 32px',
  flexGrow: 1,
  maxWidth: '1380px',
  minHeight: '100vh',
  margin: 'auto'
};

const AppLayout: React.FC<Props> = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const msalInstance = getMsalInstance();
  const { entryPointId } = useParams();

  return (
    <MsalProvider instance={msalInstance}>
      <Box sx={appLayoutStyle}>
        <Header />

        <Box sx={sidebarStyle}>
          <Box
            component="img"
            sx={{
              width: '100%',
              cursor: 'pointer'
            }}
            alt="Logo"
            src={Logo}
            onClick={() => navigate('/')}
          />
        </Box>
        <Box sx={containerStyle}>
          <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {entryPointId ? (
              <RtpFrame rtpEntryPoint={entryPointId} />
            ) : (
              <Box mt={10} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h2">Please login to use this app</Typography>
              </Box>
            )}
          </UnauthenticatedTemplate>
        </Box>
      </Box>
    </MsalProvider>
  );
};

export default AppLayout;
