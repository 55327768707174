import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { ForceGraph3D } from 'react-force-graph';
import * as dat from 'dat.gui';
import {
  DEFAULT_COLOR,
  DOCUMENT_COLOR,
  GraphViewElement,
  SECTION_COLOR,
  SELECTED_NODE,
  getLinkColor,
  getLinkLabel,
  getNodeLabel,
  getNodeThreeObject,
  mapHierarchyToGraphData
} from '../../utils/graph';
import { Hierarchy } from '../../interfaces/Graph';
import { graphService } from '../../services/graph-service';
import { useNavigate } from 'react-router-dom';

const Graph: React.FC<{
  rtpEntryPoint: string;
  isUpstream: boolean;
  sx?: SxProps;
  enableClick?: boolean;
}> = ({ rtpEntryPoint, isUpstream, sx, enableClick = true }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hierarchy, setHierarchy] = useState<Hierarchy | undefined>();
  const [queryDepth, setQueryDepth] = useState<number>(10);
  const [graphSize, setGraphSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setGraphSize({
        width: container.clientWidth,
        height: container.clientHeight
      });
    }
    const gui = new dat.GUI({ autoPlace: false });

    const controls = {
      'Query Depth': queryDepth
    };

    const guiDom = gui.domElement;
    guiDom.style.position = 'absolute';
    guiDom.style.top = '0';
    guiDom.style.right = '0';
    guiDom.style.zIndex = '2';
    const guiControl = gui.add(controls, 'Query Depth', 1, 10, 1).onFinishChange((newValue) => {
      setQueryDepth(newValue);
    });

    containerRef?.current?.appendChild(gui.domElement);

    return () => {
      gui.remove(guiControl);
    };
  }, [containerRef.current?.clientHeight, containerRef.current?.clientWidth, queryDepth]);

  const hasHierarchy = useMemo(() => hierarchy && (!!hierarchy.nodes.length || !!hierarchy.links.length), [hierarchy]);

  useEffect(() => {
    const getGraphData = async () => {
      setIsLoading(true);
      try {
        const data = await graphService.getRtpHierarchy({
          rtpName: rtpEntryPoint,
          depth: queryDepth,
          upstream: isUpstream
        });

        if (data) {
          setHierarchy(data);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    getGraphData();
  }, [rtpEntryPoint, queryDepth, isUpstream]);

  const handleNodeClick = (value: GraphViewElement, event: MouseEvent) => {
    if (value.label !== 'Undefined') {
      return;
    }

    if (event.ctrlKey) {
      const newTab = window.open('', '_blank');
      newTab!.location = `/rtp/${value.name}`;
    } else {
      navigate(`/rtp/${value.name}`);
    }
  };

  if (isLoading) {
    return (
      <Box boxShadow={5} height="400px" width="100%" display="flex" justifyContent="center" alignItems="center">
        Loading...
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef}
      id="graph"
      position="relative"
      height="400px"
      width="100%"
      sx={{ overflow: 'hidden', ...sx }}
      border="1px solid black"
      borderRadius={1}
    >
      {hasHierarchy ? (
        <ForceGraph3D
          graphData={mapHierarchyToGraphData(hierarchy!)}
          height={graphSize.height}
          width={graphSize.width}
          backgroundColor="#F8F8F8"
          enableNavigationControls
          showNavInfo={false}
          linkWidth={1}
          linkDirectionalArrowLength={5}
          linkDirectionalArrowRelPos={0.5}
          linkDirectionalParticles={1}
          linkDirectionalParticleWidth={1}
          linkOpacity={1}
          nodeThreeObject={(n) => getNodeThreeObject(n, rtpEntryPoint)}
          linkLabel={getLinkLabel}
          linkColor={getLinkColor}
          nodeLabel={getNodeLabel}
          onNodeClick={enableClick ? handleNodeClick : undefined}
          dagMode="td"
          onDagError={(error) => console.error(error)}
        />
      ) : (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center" fontSize={28} fontWeight={600}>
          No references
        </Box>
      )}
      {hasHierarchy && (
        <Box position="absolute" left={2} bottom={2} display="flex" flexDirection="column" gap={0.5}>
          <Box>Legend:</Box>
          <Box display="flex" gap={0.5} alignItems="center">
            <svg height="15" width="15">
              <rect width="15" height="15" fill={SELECTED_NODE} />
            </svg>
            Entrypoint
          </Box>
          <Box display="flex" gap={0.5} alignItems="center">
            <svg height="15" width="15">
              <circle cx="7.5" cy="7.5" r="7.5" fill={DEFAULT_COLOR} />
            </svg>
            RTP
          </Box>
          <Box display="flex" gap={0.5} alignItems="center">
            <svg height="15" width="15">
              <circle cx="7.5" cy="7.5" r="7.5" fill={SECTION_COLOR} />
            </svg>
            Legislation Section
          </Box>
          <Box display="flex" gap={0.5} alignItems="center">
            <svg height="15" width="15">
              <circle cx="7.5" cy="7.5" r="7.5" fill={DOCUMENT_COLOR} />
            </svg>
            Legislation Source
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default memo(Graph);
