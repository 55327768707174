import { useState } from 'react';
import styles from './styles.module.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Graph from './Graph';
import { useSearchParams } from 'react-router-dom';

type FilterType = 'upstream' | 'downstream';

const ImpactCard = ({ rtpEntryPoint }: { rtpEntryPoint: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterType>(
    searchParams.get('view') === 'downstream' ? 'downstream' : 'upstream'
  );

  const handleViewChange = (type: FilterType) => {
    setFilter(type);
    setSearchParams({ view: type });
  };

  return (
    <div className={styles.card}>
      <h3>Other Impacts</h3>
      <hr className={styles.hr} />
      <div className={styles.filter}>
        <span className={styles.view}>VIEW:</span>
        <RadioGroup
          row
          value={filter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleViewChange(event.target.value as FilterType);
          }}
        >
          <FormControlLabel value="upstream" control={<Radio color="default" size="small" />} label="Upstream RTPs" />
          <FormControlLabel
            value="downstream"
            control={<Radio color="default" size="small" checked={filter === 'downstream'} />}
            label="Downstream RTPs"
          />
        </RadioGroup>
      </div>
      <Graph rtpEntryPoint={rtpEntryPoint} isUpstream={filter === 'upstream'} />
    </div>
  );
};

export default ImpactCard;
