import { useState, useContext, useEffect } from 'react';
import styles from './styles.module.css';
import { ParamContext } from '../RtpBoard';
import Minus from '../../icons/minus.svg';
import Plus from '../../icons/plus.svg';
import { Box, Tooltip, Typography } from '@mui/material';
import { Reference } from '../../interfaces/Graph';
import { graphService } from '../../services/graph-service';

const options = ['Obligatory', 'Permitted', 'Forbidden', 'Excused', 'Is', 'IsNot'];

export interface ReferenceInputProps {
  reference: Reference;
  prefix: string;
}

const ReferenceInput = ({ reference, prefix }: ReferenceInputProps) => {
  const { name, type, comment, label } = reference;

  const { result, setResult } = useContext(ParamContext);
  const preDefinedAnswer = result[name] || '';

  const [open, setOpen] = useState<boolean>(false);
  const [children, setChildren] = useState<Array<Reference>>([]);

  const childrenBoard = !children?.length
    ? null
    : children.map((child, index) => (
        <ReferenceInput reference={child} prefix={`${prefix}.${index + 1}`} key={index} />
      ));

  useEffect(() => {
    const init = async () => {
      try {
        if (label === 'Undefined') {
          const refs = await graphService.getRtpReferences({ rtpName: reference.name });
          setChildren(refs);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [label, reference]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setResult &&
      setResult({
        ...result,
        [name]: event.target.value
      });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResult &&
      setResult({
        ...result,
        [name]: event.target.value
      });
  };

  return (
    <Box mb={1.25}>
      <Box display="flex" alignItems="flex-end">
        {children?.length ? (
          <Box
            component="img"
            sx={{
              width: 50
            }}
            id={prefix}
            src={open ? Minus : Plus}
            alt="control-button"
            onClick={() => {
              setOpen((open) => !open);
            }}
          />
        ) : (
          <Box width="50px" />
        )}
        <Typography
          sx={
            prefix === ''
              ? {
                  padding: 1.25,
                  backgroundColor: '#000000',
                  color: '#fff',
                  lineHeight: '36px',
                  width: '50px',
                  minWidth: '50px',
                  textAlign: 'center'
                }
              : {
                  padding: 1.25,
                  backgroundColor: '#5e5e5e',
                  color: '#fff',
                  lineHeight: '36px'
                }
          }
          id={children?.length ? undefined : prefix}
        >
          {prefix}
        </Typography>
        <div className={styles.descriptionBoard}>
          {comment && (
            <Tooltip title={comment}>
              <div className={styles.description}>
                <p>{comment}</p>
              </div>
            </Tooltip>
          )}
          {type === 'ATOM' && !open && (
            <select name="" id="" value={preDefinedAnswer} onChange={handleSelectChange} className={styles.select}>
              <option value={''}>-</option>
              {options.map((option, index) => {
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </select>
          )}

          {(type === 'NUMBER' || type === 'STRING' || type === 'DURATION') && !open && (
            <input
              type="text"
              value={preDefinedAnswer}
              placeholder="input"
              onChange={handleTypeChange}
              className={styles.input}
            />
          )}
        </div>
      </Box>
      <Box sx={{ pl: 3.75 }}>{open && children?.length && childrenBoard}</Box>
    </Box>
  );
};

export default ReferenceInput;
